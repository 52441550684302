<template>
  <div>
    <Row :gutter="24">
      <Col :span="8">
      <Card>
        <p slot="title">基础信息</p>
        <Tag slot="extra" color="blue">{{aftersalebill_obj.ProcessStatusDesc}}</Tag>
          <Button
            slot="extra"
            style="margin-left: 30px;"
            type="ifno"
            @click="GoBack()"
            icon="md-arrow-back"
          >返回</Button>

        <p>工单编号: {{aftersalebill_obj.AfterSaleCode}}</p>
        <p>客户姓名: {{aftersalebill_obj.CustomerName}}</p>
        <p>客户电话: {{aftersalebill_obj.CustomerTel}}</p>
        <p>售后类别: {{aftersalebill_obj.AfterBillTypeDesc}}</p>
        <p>详细地址: {{aftersalebill_obj.AddressDetail}}</p>
        <p>收费: {{aftersalebill_obj.Charge}}</p>
        <p>收费类型: {{aftersalebill_obj.ChargeTypeDesc}}</p>
        <p>质保卡类型: {{aftersalebill_obj.WarrantyType==1?'双重质保卡':aftersalebill_obj.WarrantyType==2?'系统质保卡':''}}</p>
        <p>质保卡: {{aftersalebill_obj.WarrantyNum}}</p>
        <p>试压员: {{aftersalebill_obj.WorkerName}}</p>
        <p>试压时间: {{aftersalebill_obj.WorkerTime}}</p>
        <p>备注信息: {{aftersalebill_obj.DetailText}}</p>
        <p>未处理原因: {{aftersalebill_obj.Reason}}</p>
        <div style="margin-top:20px;">
          <p style="font-size:16px;font-weight:bold;">售后处理信息</p>
          <p>是否收费：{{aftersalebill_obj.IsIncome==0?'未收费（需公司收费）':aftersalebill_obj.IsIncome==1?'已收费':aftersalebill_obj.IsIncome==2?'无需收费':aftersalebill_obj.IsIncome==null?'':'需收费（对方不出费用）'}}</p>
          <p>有偿售后（收费）：{{aftersalebill_obj.Income}}</p>
          <p>收费备注：{{aftersalebill_obj.IncomeRemark}}</p>
          <p>费用明细：{{aftersalebill_obj.CostDetails}}</p>
          <p>对接单位：{{aftersalebill_obj.DecorateCompany}}</p>
          <p>补偿/其他金额：{{aftersalebill_obj.CostPrice}}</p>
          <p>支出费用备注：{{aftersalebill_obj.CostRemark}}</p>
          <p>是否有售后问题: {{aftersalebill_obj.HasProblem==0?'是':aftersalebill_obj.HasProblem==1?'否':'等待进一步确认'}}</p>
          <p>售后位置: {{aftersalebill_obj.afterbillPositionName}}</p>
          <p>详细位置: {{aftersalebill_obj.DetailPosition}}</p>
          <p>现场类型: {{aftersalebill_obj.SceneTypeDesc}}</p>
          <p>详细类型: {{aftersalebill_obj.detailTypeName}}</p>
          <p>热源: {{aftersalebill_obj.HeatSource}}</p>
          <p>热水器或锅炉名称: {{aftersalebill_obj.SourceName}}</p>
          <p>已使用几年: {{aftersalebill_obj.UsedYear}}</p>
          <p>供水温度: {{aftersalebill_obj.WaterTemp}}</p>
          <p>热水器或锅炉是否有故障: {{aftersalebill_obj.IsFault}}</p>
          <p>增压设备: {{aftersalebill_obj.IsAddEquip}}</p>
          <p>回水: {{aftersalebill_obj.IsBackWater}}</p>
          <p>是否维修: {{aftersalebill_obj.IsRepair==0?'否':aftersalebill_obj.IsRepair==1?'是':aftersalebill_obj.IsRepair==null?'':'客户自修'}}</p>
          <p>前置过滤器: {{aftersalebill_obj.PreFilterName==null||aftersalebill_obj.PreFilterName==''?'否':'是('+aftersalebill_obj.PreFilterName+')'}}</p>
          <p>&nbsp;&nbsp;&nbsp;购买渠道: {{aftersalebill_obj.PreBuyChannel==null||aftersalebill_obj.PreBuyChannel==''?'-':aftersalebill_obj.PreBuyChannel}}</p>
          <p>&nbsp;&nbsp;&nbsp;购买价格: {{aftersalebill_obj.PreBuyPrice==null||aftersalebill_obj.PreBuyPrice==''?'-':aftersalebill_obj.PreBuyPrice}}</p>
          <p>净水设备: {{aftersalebill_obj.WaterName==null||aftersalebill_obj.WaterName==''?'否':'是('+aftersalebill_obj.WaterName+')'}}</p>
          <p>&nbsp;&nbsp;&nbsp;购买渠道: {{aftersalebill_obj.WatBuyChannel==null||aftersalebill_obj.WatBuyChannel==''?'-':aftersalebill_obj.WatBuyChannel}}</p>
          <p>&nbsp;&nbsp;&nbsp;购买价格: {{aftersalebill_obj.WatBuyPrice==null||aftersalebill_obj.WatBuyPrice==''?'-':aftersalebill_obj.WatBuyPrice}}</p>
          <p>售后人员完结备注:{{aftersalebill_obj.RespReMark}}</p>
          <p>试压开始时间: {{aftersalebill_obj.StartGuaranteedTime}}</p>
          <p>加压前压力: {{aftersalebill_obj.PrePressure}} 公斤</p>
          <p>试压结束时间: {{aftersalebill_obj.EndGuaranteedTime}}</p>
          <p>加压后压力: {{aftersalebill_obj.AftPressure}} 公斤</p>
          <p>服务开始时间(第1次): {{aftersalebill_obj.StartService1Time}}</p>
          <p>服务结束时间(第1次): {{aftersalebill_obj.EndService1Time}}</p>
          <!-- <p>服务开始时间(第2次): {{aftersalebill_obj.StartService2Time}}</p>
          <p>服务结束时间(第2次): {{aftersalebill_obj.EndService2Time}}</p>
          <p>服务开始时间(第3次): {{aftersalebill_obj.StartService3Time}}</p>
          <p>服务结束时间(第3次): {{aftersalebill_obj.EndService3Time}}</p> -->
        </div>
        

      </Card>
      </Col>
      <Col :span="16">
      <Tabs type="card" @on-click="tabchange">
        <TabPane label="现场勘察图" name="step1">
          <div class="img-list">
            <div v-for="(item, index) in step1imgs" @click="viewimg(item,1)" :key="index" class="img-cell" v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </TabPane>
        <TabPane label="修复过程图" name="step2">
          <div class="img-list">
            <div v-for="(item, index) in step2imgs" @click="viewimg(item,2)" :key="index" class="img-cell" v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </TabPane>
        <TabPane label="修复后图" name="step3">
          <div class="img-list">
            <div v-for="(item, index) in step3imgs" @click="viewimg(item,3)" :key="index" class="img-cell" v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </TabPane>
        <TabPane label="单据图" name="step4">
          <div class="img-list">
            <div v-for="(item, index) in step4imgs" @click="viewimg(item,4)" :key="index" class="img-cell" v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </TabPane>
        <TabPane label="材料单" name="step5">
          <div class="detail-form" style="margin-top:10px;">
            <!-- <div style="padding:10px 0 10px 0">
              <p>补偿/其他金额：{{aftersalebill_obj.CostPrice}}</p>
              <p style="margin-top: 10px;">支出费用备注：{{aftersalebill_obj.CostRemark}}</p>
            </div>
            <div style="padding:10px 0 10px 0">
              <p>有偿售后（收费）：{{aftersalebill_obj.Income}}</p>
              <p style="margin-top: 10px;">收费备注：{{aftersalebill_obj.IncomeRemark}}</p>
            </div> -->
            <Table :columns="billdetail_col" :data="billdetail_form"></Table>
            <!-- <Button style="margin-top:1vh" @click="SaveDetail" type="success" long>保存</Button> -->
          </div>
        </TabPane>
      </Tabs>
      </Col>
    </Row>
    <Modal v-model="imgviewmodal" :type="info" width="840px">
      <p slot="header" style="text-align:center">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preImg()" @keyup.left="preImg">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;object-fit: contain;" :src="currentImgurl" alt />
        <div @click="nextImg()" @keyup.right="nextImg">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div slot="footer">
        <Button type="info" @click="viewOriginImg">查看原图</Button>
        <Button @click="close">关闭</Button>
      </div>
      <!-- <div style="text-align:center">
        <img style="max-width: 800px;max-height: 600px;" :src="currentImgurl" alt="">
      </div> -->
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step1imgs: [],
      step2imgs: [],
      step3imgs: [],
      step4imgs: [],
      aftcompress: [],
      aftersalebill_obj: {},
      billdetail_form: [],
      billdetail_col: [
        // {
        //   title: "ID",
        //   key: "ID"
        // },
        {
          title: "类别",
          key: "ProductCategoryDesc"
        },
        {
          title: "产品名",
          key: "MaterialName"
        },
        {
          title: "规格型号",
          key: "Standard"
        },
        {
          title: "使用数量",
          key: "MaterialCount"
        }
      ],
      imgviewmodal: false,
      currentImgurl:"",
      dis: false,
      ischecks: "",
      active: "step1",
      // base_url: "http://121.41.11.131:8088/imgs/",      
      // base_url: "http://localhost:24737/imgs/"
      base_url: this.$file.url+"imgs/",
    };
  },
  methods: {
    tabchange(e) {
      this.active=e;
      if (e == "step5") {
        this.GetAllMaterial();
        this.GetBillDetail();
      }
    },
    viewimg(item, type) {
      this.imgviewmodal = true;
      // var foldepath = "";
      // switch (type) {
      //   case 1:
      //     foldepath = "/xianchang/";
      //     break;
      //   case 2:
      //     foldepath = "/xiufuguocheng/";
      //     break;
      //   case 3:
      //     foldepath = "/xiufuwancheng/";
      //     break;
      //   case 4:
      //     foldepath = "/danju/";
      //     break;
      // }
      // this.currentImgurl=this.base_url+this.aftersalebill_obj.AfterSaleCode+foldepath+item+'.jpg';
      this.currentImgurl = item;
      console.log(item);
    },
    SaveDetail() {
      var postdata = [];
      this.billdetail_form.forEach(item => {
        if (item.MaterialCount > 0) {
          postdata.push(item);
        }
      });
      this.$http
        .post("/AfterSaleBillDetail/SaveBillDetail", {
          id: this.$route.params.id,
          detail: postdata
        })
        .then(() => {
          this.GetAfterBill();
          this.GetAllMaterial();
        });
    },
    hard_delete_img() {},
    GetAfterBill() {
      this.$http
        .get("/aftersalebill/EditAfterSaleBillPre", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          this.$Spin.hide();
          // 查询售后图片是否审核，未审核则按钮可用，已审核则按钮不可用
          this.ischecks = res.data.Data.Status;
          if(this.ischecks == 0){
            this.dis = false;
          }else{
            this.dis = true;
          }

          this.aftersalebill_obj = res.data.Data;
          if (res.data.Data.Step1Imgs != null) {
            if (res.data.Data.Step1Imgs.length > 0) {
              // this.step1imgs = res.data.Data.Step1Imgs.split(",");
              var imgarray = res.data.Data.Step1Imgs.split(",");
              var imgUrlArray = imgarray.map(item => {
                return (this.base_url + this.aftersalebill_obj.AfterSaleCode + "/xianchang" + "/" + item + ".jpg");
              });
              this.step1imgs = imgUrlArray;
              this.step1imgs.pop();
            }
          }
          if (res.data.Data.Step2Imgs != null) {
            if (res.data.Data.Step2Imgs.length > 0) {
              // this.step2imgs = res.data.Data.Step2Imgs.split(",");
              var imgarray2 = res.data.Data.Step2Imgs.split(",");
              var imgUrlArray2 = imgarray2.map(item => {
                return (this.base_url + this.aftersalebill_obj.AfterSaleCode + "/xiufuguocheng" + "/" + item + ".jpg");
              });
              this.step2imgs = imgUrlArray2;
              this.step2imgs.pop();
            }
          }
          if (res.data.Data.Step3Imgs != null) {
            if (res.data.Data.Step3Imgs.length > 0) {
              // this.step3imgs = res.data.Data.Step3Imgs.split(",");
              var imgarray3 = res.data.Data.Step3Imgs.split(",");
              var imgUrlArray3 = imgarray3.map(item => {
                return (this.base_url + this.aftersalebill_obj.AfterSaleCode + "/xiufuwancheng" + "/" + item + ".jpg");
              });
              this.step3imgs = imgUrlArray3;
              this.step3imgs.pop();
            }
          }
          if (res.data.Data.Step4Imgs != null) {
            if (res.data.Data.Step4Imgs.length > 0) {
              // this.step4imgs = res.data.Data.Step4Imgs.split(",");
              var imgarray4 = res.data.Data.Step4Imgs.split(",");
              var imgUrlArray4 = imgarray4.map(item => {
                return (this.base_url + this.aftersalebill_obj.AfterSaleCode + "/danju" + "/" + item + ".jpg");
              });
              this.step4imgs = imgUrlArray4;
              this.step4imgs.pop();
            }
          }
        });
    },
    GetBillDetail() {
      this.$http
        .get("/AfterSaleBillDetail/GetAfterSaleBillDetail", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          this.billdetail_form = res.data;
        });
    },
    GetAllMaterial() {
      this.$http.get("/AfterSaleBillDetail/GetAllMaterials").then(res => {
        this.billdetail_form = res.data;
        this.billdetail_form.forEach(item => {
          this.$set(item, "MaterialCount", 0);
        });
        this.GetBillDetail();
      });
    },
    GoBack: function(e) {
      this.$router.go(-1);
    },
    preImg() {
      var pic = this.active;
      switch (pic) {
        case "step1":
          var index1 = this.step1imgs.indexOf(this.currentImgurl);
          var nextImgUrl1 = "";
          if (index1 == 0) {
            nextImgUrl1 = this.step1imgs[this.step1imgs.length - 1];
          } else {
            nextImgUrl1 = this.step1imgs[index1 - 1];
          }
          this.currentImgurl = nextImgUrl1;
          break;
        case "step2":
          var index2 = this.step2imgs.indexOf(this.currentImgurl);
          var nextImgUrl2 = "";
          if (index2 == 0) {
            nextImgUrl2 = this.step2imgs[this.step2imgs.length - 1];
          } else {
            nextImgUrl2 = this.step2imgs[index2 - 1];
          }
          this.currentImgurl = nextImgUrl2;
          break;
        case "step3":
          var index3 = this.step3imgs.indexOf(this.currentImgurl);
          var nextImgUrl3 = "";
          if (index3 == 0) {
            nextImgUrl3 = this.step3imgs[this.step3imgs.length - 1];
          } else {
            nextImgUrl3 = this.step3imgs[index3 - 1];
          }
          this.currentImgurl = nextImgUrl3;
          break;
        case "step4":
          var index4 = this.step4imgs.indexOf(this.currentImgurl);
          var nextImgUrl4 = "";
          if (index4 == 0) {
            nextImgUrl4 = this.step4imgs[this.step4imgs.length - 1];
          } else {
            nextImgUrl4 = this.step4imgs[index4 - 1];
          }
          this.currentImgurl = nextImgUrl4;
          break;
      } 
    },
    nextImg() {
      var pic = this.active;
      switch (pic) {
        case "step1":
          var index1 = this.step1imgs.indexOf(this.currentImgurl);
          var nextImgUrl1 = "";
          if (index1 < this.step1imgs.length - 1) {
            nextImgUrl1 = this.step1imgs[index1 + 1];
          } else {
            nextImgUrl1 = this.step1imgs[0];
          }
          this.currentImgurl = nextImgUrl1;
          break;
        case "step2":
          var index2 = this.step2imgs.indexOf(this.currentImgurl);
          var nextImgUrl2 = "";
          if (index2 < this.step2imgs.length - 1) {
            nextImgUrl2 = this.step2imgs[index2 + 1];
          } else {
            nextImgUrl2 = this.step2imgs[0];
          }
          this.currentImgurl = nextImgUrl2;
          break;
        case "step3":
          var index3 = this.step3imgs.indexOf(this.currentImgurl);
          var nextImgUrl3 = "";
          if (index3 < this.step3imgs.length - 1) {
            nextImgUrl3 = this.step3imgs[index3 + 1];
          } else {
            nextImgUrl3 = this.step3imgs[0];
          }
          this.currentImgurl = nextImgUrl3;
          break;
        case "step4":
          var index4 = this.step4imgs.indexOf(this.currentImgurl);
          var nextImgUrl4 = "";
          if (index4 < this.step4imgs.length - 1) {
            nextImgUrl4 = this.step4imgs[index4 + 1];
          } else {
            nextImgUrl4 = this.step4imgs[0];
          }
          this.currentImgurl = nextImgUrl4;
          break;
      }
    },
    close(){
      this.imgviewmodal=false;
    },
    viewOriginImg(){
      window.open(this.currentImgurl);
    },
  },
  created() {
    this.GetAfterBill();
  }
};
</script>

<style>
.detail-form {
}
</style>
